import { createStore } from "vuex";
import auth from "./modules/auth";
import dashboard from "./modules/dashboard";
import createPersistedState from "vuex-plugin-persistedstate";
export default createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    dashboard,
  },
});
