import handleRequest from "@/http";
import router from "@/router";
const state = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  getUser: (state) => state.user,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setRefToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
};

const actions = {
  async handleUserLogin({ commit, state }, credentials) {
    try {
      const response = await handleRequest.post("/sessions", credentials);
      console.log(response.data);
      const data = response.data;
      await commit("setAccessToken", data.accessToken);
      await commit("setRefToken", data.refreshToken);
      await commit("setIsAuthenticated", true);
      await commit("setUser", data.user);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async handleUserLogout({ commit }) {
    try {
      router.push({
        name: "home",
      });
      setTimeout(async () => {
        await handleRequest.delete("/sessions");
        commit("setAccessToken", null);
        commit("setRefToken", null);
        commit("setIsAuthenticated", false);
        commit("setUser", null);
      }, 5000);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
