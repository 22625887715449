import axios from "axios";
import store from "@/store";
import router from "@/router";
import { API_BASE } from "@/config";
const handleRequest = axios.create({
  baseURL: API_BASE,
});

handleRequest.interceptors.request.use(
  (config) => {
    const { accessToken, refreshToken } = store?.state?.auth || {};

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (refreshToken) {
      config.headers["x-refresh"] = refreshToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

handleRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status) {
      const status = error.response.status;

      if (status === 403) {
        router.push({
          path: "/",
        });
      }
    }

    return Promise.reject(error);
  }
);

export default handleRequest;
