import handleRequest from "@/http";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  // Dashboard
  async getDashboardStats({ commit, state }, payload) {
    const endpoint = payload.customer
      ? `/dashboard/company/${payload.customer}`
      : "/dashboard";
    try {
      const { data } = await handleRequest.get(endpoint);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAllTransactions({ commit, state }, payload) {
    try {
      let requestUrl = "/transaction";
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        requestUrl = `/customer/transactions`;
        queryString.push(`user=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(
        `${requestUrl}?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAllOrders({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        queryString.push(`customer=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(
        `/orders?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Get Companies
  async getAllCompanies({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      if (payload?.customer) {
        queryString.push(`customer=${payload?.customer}`);
      }
      const { data } = await handleRequest.get(
        `/user?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Create new company
  async createNewCompany({ commit, state }, payload) {
    try {
      await handleRequest.post(`/user`, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Get company users
  async getCompanyAccounts({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
        `user=${payload?.customer || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/customers?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // Get All customers
  async getAllCustomers({ commit, state }, payload) {
    try {
      const queryString = [
        `limit=${payload?.limit || ""}`,
        `page=${payload?.page || ""}`,
      ];
      const { data } = await handleRequest.get(
        `/customers?${queryString.join("&")}`
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
