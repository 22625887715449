import "bootstrap/dist/js/bootstrap.js";
import ToastPlugin from "vue-toast-notification";
import VueApexCharts from "vue3-apexcharts";
import ItemLoader from "@/components/ItemLoader.vue";
import ModalWrapper from "@/components/ModalWrapper.vue";
import Goback from "@/components/dashboard/global/Goback";
import DynamicPagination from "@/components/dashboard/global/DynamicPagination";
import { createHead } from "unhead";
const head = createHead();
export default function useImports(app) {
  app.use(VueApexCharts);
  app.use(head);
  app.use(ToastPlugin, {
    position: "top-right",
  });
  app.component("ItemLoader", ItemLoader);
  app.component("ModalWrapper", ModalWrapper);
  app.component("Goback", Goback);
  app.component("DynamicPagination", DynamicPagination);
}
