<template>
  <div class="p-4">
    <div class="col-lg-4 mx-auto my-5 pt-5">
      <div class="nx-card p-4">
        <div class="my-4">
          <h4 class="text__body--x-large text--semibold text--dark">
            Resume your journery
          </h4>
        </div>
        <form @submit.prevent="handleLogin">
          <div class="form-group my-4">
            <div class="input-group nx-input-group">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                v-model="email"
              />
              <span class="input-group-text" id="basic-addon1">
                <i class="bi bi-person"></i>
              </span>
            </div>
            <div
              class="text-danger text__body--smaller"
              v-if="email && v$.email.$invalid"
            >
              Be sure to provide a valid email
            </div>
          </div>
          <div class="form-group my-4">
            <div class="input-group nx-input-group">
              <input
                :type="showPassword ? 'text' : 'password'"
                class="form-control"
                placeholder="Password"
                aria-label="Password"
                aria-describedby="basic-addon1"
                v-model="password"
              />
              <span
                class="input-group-text"
                id="basic-addon1"
                @click="showPassword = !showPassword"
              >
                <i
                  :class="[showPassword ? 'bi bi-eye' : 'bi bi-eye-slash']"
                ></i>
              </span>
            </div>
            <div
              class="text-danger text__body--smaller"
              v-if="password && v$.password.$invalid"
            >
              Password should be at least 8 characters
            </div>
          </div>
          <div class="mt-5">
            <button
              class="nx-button nx-button--primary w-100"
              :disabled="isLoading || formInvalid"
            >
              Login as Admin <ItemLoader v-if="isLoading" />
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";
import { useHead } from "unhead";
export default {
  name: "HomeView",
  setup() {
    useHead({
      title: "Admin Login",
    });
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      showPassword: false,
    };
  },
  validations() {
    return {
      email: { required, email },
      password: { required, minLength: minLength(8) },
    };
  },
  computed: {
    formInvalid() {
      return this.v$.password.$invalid || this.v$.email.$invalid;
    },
  },
  methods: {
    ...mapActions(["handleUserLogin"]),
    async handleLogin() {
      const { email, password } = this;
      if (this.isLoading || this.formInvalid) {
        return;
      }
      this.isLoading = true;
      try {
        await this.handleUserLogin({
          email,
          password,
        });
        this.$router.push({
          name: "Overview",
        });
        this.isLoading = false;
      } catch (error) {
        if (error.response.data && error?.response?.data?.message) {
          this.$toast.error(error?.response?.data?.message);
        } else {
          this.$toast.error("An unexpected error occurred. Please try again");
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
