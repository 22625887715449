<template>
  <div class="mx-1">
    <div
      class="nx-spinner"
      :class="{
        'nx-spinner--button': variant == 'button',
        'nx-spinner--layout': variant == 'layout',
        'nx-spinner--dark': variant == 'dark',
        'nx-spinner--center': position == 'center',
        'nx-spinner--small': isSmall,
      }"
    ></div>
    <div
      class="mt-4 text-center text--secondary text__body--smaller"
      v-if="hasMessage"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemLoader",
  props: {
    hasMessage: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "button",
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Loading",
    },
    position: {
      type: String,
      default: "center",
    },
  },
};
</script>

<style>
.nx-spinner {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 50%;
  animation: spin 0.4s ease-in-out infinite;
}
.nx-spinner.nx-spinner--button {
  border-top: 1px solid transparent;
}
.nx-spinner.nx-spinner--layout {
  width: 40px;
  height: 40px;
  border-top: 1px solid var(--nx-main-color);
}
.nx-spinner.nx-spinner--dark {
  width: 40px;
  height: 40px;
  border-top: 1px solid #1c1c1c;
}
.nx-spinner.nx-spinner--small {
  width: 20px;
  height: 20px;
}
.nx-spinner.nx-spinner--center {
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
